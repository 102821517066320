<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-center">
        Showing page {{ pages == 0 ? 0 : page }} of {{ pages }}
      </v-col>
      <v-col align="center">
        <v-text-field
          v-model="localPage"
          :key="reload"
          ref="input"
          dense
          type="number"
          outlined
          hide-details
          background-color="white"
          :style="{ width: '300px' }"
          @keypress.enter="$refs.input.blur()"
          @input="update(false)"
          @blur="update(true)"
        >
          <template v-slot:prepend>
            <v-btn
              :disabled="localPage <= 1"
              @click="
                localPage -= 1;
                update(true);
              "
              text
              class="mt-n1"
              color="msaBlue"
            >
              Previous
            </v-btn>
          </template>
          <template v-slot:append-outer>
            <v-btn
              :disabled="localPage >= pages"
              @click="
                localPage += 1;
                update(true);
              "
              text
              class="mt-n1"
              color="msaBlue"
            >
              Next
            </v-btn>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CustomPagination',
  props: ['page', 'pages'],
  data() {
    return {
      timeout: null,
      reload: false,
      localPage: 1,
    };
  },
  methods: {
    validateInput(value) {
      if (isNaN(parseInt(value)) || value < 1) {
        return 1;
      } else if (value > this.pages) {
        return parseInt(this.pages);
      } else {
        return Math.round(value);
      }
    },
    update(immediately) {
      clearTimeout(this.timeout);
      if (this.localPage != this.page) {
        if (immediately) {
          this.$emit('update', this.validateInput(this.localPage));
        } else {
          this.timeout = setTimeout(() => {
            this.$emit('update', this.validateInput(this.localPage));
          }, 1500);
        }
      }
    },
    init() {
      this.localPage = this.pages == 0 ? 0 : this.page;
    },
  },
  watch: {
    pages() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
