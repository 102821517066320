<template>
  <span
    @mouseover="showSort"
    @mouseleave="hideSort"
    class="clickable black--text"
    @click="$emit('update:sort', sortValue)"
    :style="{ 'font-size': '0.75rem' }"
  >
    {{ header.text }}
    <span v-if="header.isSortingBy">
      <v-icon v-if="header.isSortingDesc" small> mdi-arrow-down </v-icon>
      <v-icon v-else small> mdi-arrow-up </v-icon>
    </span>
    <span v-else>
      <v-icon
        :color="showSortArrow ? 'black' : 'transparent'"
        small
        v-if="!sortDescFirst"
      >
        mdi-arrow-up
      </v-icon>
      <v-icon :color="showSortArrow ? 'black' : 'transparent'" small v-else>
        mdi-arrow-down
      </v-icon>
    </span>
  </span>
</template>

<script>
export default {
  name: 'CompanyFormNonFilterableHeader',
  props: {
    header: {
      type: Object,
    },
    sortDescFirst: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showSortArrow: false,
    };
  },
  computed: {
    sortValue() {
      if (this.header.isSortingBy) {
        return this.header.isSortingDesc ? 0 : 1;
      } else {
        return this.sortDescFirst ? 1 : 0;
      }
    },
  },
  methods: {
    showSort() {
      if (this.header.isSortingBy) return;
      this.showSortArrow = true;
    },
    hideSort() {
      this.showSortArrow = false;
    },
  },
};
</script>

<style></style>
