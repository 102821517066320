<template>
  <v-card tile width="240">
    <v-card-title>
      <v-row class="font-weight-regular">
        <v-col>Filters</v-col>
        <v-col align="right" v-if="isMobile">
          <v-btn icon @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-col align="right" v-if="!isMobile">
          <v-btn
            @click="clearFilters"
            text
            color="msaBlue"
            :disabled="isDefaultView"
          >
            C<span class="text-lowercase">lear filters</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="12"> Status</v-col>
        <v-col cols="12">
          <v-checkbox
            v-model="localFilter.status"
            :value="$constants.FORM_INSTANCE_STATUS.ASSIGNED"
            dense
            hide-details
            @change="isMobile ? null : confirm()"
          >
            <template v-slot:label v-if="loaded">
              {{ statusLabel($constants.FORM_INSTANCE_STATUS.ASSIGNED) }}
            </template>
            <template v-slot:label v-else>
              Assigned
              <v-progress-circular
                indeterminate
                size="20"
                color="msaBlue"
              ></v-progress-circular>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="localFilter.status"
            :value="$constants.FORM_INSTANCE_STATUS.SUBMITTED"
            dense
            hide-details
            @change="isMobile ? null : confirm()"
          >
            <template v-slot:label v-if="loaded">
              {{ statusLabel($constants.FORM_INSTANCE_STATUS.SUBMITTED) }}
            </template>
            <template v-slot:label v-else>
              Submitted
              <v-progress-circular
                indeterminate
                size="20"
                color="msaBlue"
              ></v-progress-circular>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="localFilter.status"
            :value="$constants.FORM_INSTANCE_STATUS.REVIEWED"
            dense
            hide-details
            @change="isMobile ? null : confirm()"
          >
            <template v-slot:label v-if="loaded">
              {{ statusLabel($constants.FORM_INSTANCE_STATUS.REVIEWED) }}
            </template>
            <template v-slot:label v-else>
              Reviewed
              <v-progress-circular
                indeterminate
                size="20"
                color="msaBlue"
              ></v-progress-circular>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="localFilter.status"
            :value="$constants.FORM_INSTANCE_STATUS.FINALIZED"
            dense
            hide-details
            @change="isMobile ? null : confirm()"
          >
            <template v-slot:label v-if="loaded">
              {{ statusLabel($constants.FORM_INSTANCE_STATUS.FINALIZED) }}
            </template>
            <template v-slot:label v-else>
              Finalized
              <v-progress-circular
                indeterminate
                size="20"
                color="msaBlue"
              ></v-progress-circular>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-divider class="mt-3"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="localFilter.isPrivate"
            :true-value="1"
            :false-value="0"
            dense
            hide-details
            @change="isMobile ? null : confirm()"
          >
            <template v-slot:label v-if="loaded">
              Private form ({{ counts.privateCount }})
            </template>
            <template v-slot:label v-else>
              Private form
              <v-progress-circular
                indeterminate
                size="20"
                color="msaBlue"
              ></v-progress-circular>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="localFilter.pastSyncCutOff"
            :true-value="1"
            :false-value="0"
            dense
            hide-details
            @change="isMobile ? null : confirm()"
          >
            <template v-slot:label v-if="loaded">
              Past sync cutoff ({{ counts.pastSyncCount }})
            </template>
            <template v-slot:label v-else>
              Past sync cutoff
              <v-progress-circular
                indeterminate
                size="20"
                color="msaBlue"
              ></v-progress-circular>
            </template>
          </v-checkbox>
          <v-checkbox
            v-model="localFilter.hasFormAudit"
            :true-value="1"
            :false-value="0"
            dense
            hide-details
            @change="isMobile ? null : confirm()"
          >
            <template v-slot:label v-if="loaded">
              Has form audit ({{ counts.hasAuditCount }})
            </template>
            <template v-slot:label v-else>
              Has form audit
              <v-progress-circular
                indeterminate
                size="20"
                color="msaBlue"
              ></v-progress-circular>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
      <v-row no-gutters>
        <v-col cols="12"> Has corrective actions</v-col>
        <v-col cols="12">
          <v-radio-group
            v-model="localFilter.hasCorrectiveActions"
            column
            dense
            hide-details
            class="mt-0"
            @change="isMobile ? null : confirm()"
          >
            <v-radio :value="2" color="msaBlue">
              <template v-slot:label v-if="loaded">
                All ({{
                  localFilter.hasCorrectiveActions == 2
                    ? counts.totalCount
                    : '0'
                }})
              </template>
              <template v-slot:label v-else>
                All
                <v-progress-circular
                  indeterminate
                  size="20"
                  color="msaBlue"
                ></v-progress-circular>
              </template>
            </v-radio>
            <v-radio :value="1" color="msaBlue">
              <template v-slot:label v-if="loaded">
                Yes ({{ counts.hasCaCount }})
              </template>
              <template v-slot:label v-else>
                Yes
                <v-progress-circular
                  indeterminate
                  size="20"
                  color="msaBlue"
                ></v-progress-circular>
              </template>
            </v-radio>
            <v-radio :value="0" color="msaBlue">
              <template v-slot:label v-if="loaded">
                No ({{ counts.notHasCaCount }})
              </template>
              <template v-slot:label v-else>
                No
                <v-progress-circular
                  indeterminate
                  size="20"
                  color="msaBlue"
                ></v-progress-circular>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
      <v-row no-gutters>
        <v-col cols="12"> Corrective action status</v-col>
        <v-col cols="12">
          <v-radio-group
            v-model="localFilter.correctiveActionStatus"
            column
            dense
            hide-details
            class="mt-0"
            @change="isMobile ? null : confirm()"
          >
            <v-radio :value="2" color="msaBlue">
              <template v-slot:label v-if="loaded">
                All ({{
                  localFilter.correctiveActionStatus == 2
                    ? counts.caCount
                    : '0'
                }})
              </template>
              <template v-slot:label v-else>
                All
                <v-progress-circular
                  indeterminate
                  size="20"
                  color="msaBlue"
                ></v-progress-circular>
              </template>
            </v-radio>
            <v-radio :value="1" color="msaBlue">
              <template v-slot:label v-if="loaded">
                Resolved ({{ counts.resolvedCaCount }})
              </template>
              <template v-slot:label v-else>
                Resolved
                <v-progress-circular
                  indeterminate
                  size="20"
                  color="msaBlue"
                ></v-progress-circular>
              </template>
            </v-radio>
            <v-radio :value="0" color="msaBlue">
              <template v-slot:label v-if="loaded">
                Unresolved ({{ counts.unresolvedCaCount }})
              </template>
              <template v-slot:label v-else>
                Unresolved
                <v-progress-circular
                  indeterminate
                  size="20"
                  color="msaBlue"
                ></v-progress-circular>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="mt-4"> Tags</v-col>
        <v-col :cols="isMobile ? 4 : 12">
          <v-autocomplete
            v-model="localFilter.tagIds"
            outlined
            dense
            multiple
            placeholder="Select tag(s)"
            label="Select tag(s)"
            flat
            solo
            hide-details
            :items="tags"
            item-text="val"
            item-value="id"
            clearable
            chips
            deletable-chips
            @change="onTagChanged"
            @blur="isMobile ? null : confirm()"
          >
            <template v-slot:[`selection`]="{ item, index }">
              <v-chip
                :color="item.deletedAt ? 'red' : 'msaBlue'"
                dark
                close
                small
                @click:close="localFilter.tagIds.splice(index, 1)"
              >
                <div class="overflow-x-hidden text-truncate">
                  {{ item.val }}
                </div>
              </v-chip>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <!-- this is to make sure the tags field does not get blocked by the two buttons below -->
      <v-row v-if="isMobile">
        <v-col :style="{ height: '40px' }"> </v-col>
      </v-row>
    </v-card-text>
    <v-footer
      v-if="isMobile"
      padless
      tile
      :style="{
        position: 'absolute',
        bottom: 0,
        'z-index': 999,
        width: '100%',
      }"
      color="transparent"
    >
      <v-container fluid>
        <v-row>
          <v-col class="grow" align="right">
            <v-btn
              class="lightBg msaBlue--text"
              depressed
              @click="clearFilters()"
              :disabled="isDefaultView"
            >
              clear filters
            </v-btn>
          </v-col>
          <v-col class="shrink">
            <v-btn class="msaBlue white--text" depressed @click="confirm()">
              done
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-card>
</template>

<script>
export default {
  name: 'FormInstancesFilterRevamped',
  props: {
    counts: {
      type: Object,
      default: () => ({
        assignedCount: 0,
        submittedCount: 0,
        pastSyncCount: 0,
        privateCount: 0,
        resolvedCaCount: 0,
        hasAuditCount: 0,
        unresolvedCaCount: 0,
        caCount: 0,
        finalizedCount: 0,
        totalCount: 0,
        reviewedCount: 0,
        hasCaCount: 0,
        notHasCaCount: 0,
        totalRecordCount: 0,
      }),
    },
    filters: {
      type: Object,
    },
  },
  computed: {
    statusLabel() {
      return (statusId) => {
        switch (statusId) {
          case this.$constants.FORM_INSTANCE_STATUS.ASSIGNED:
            return `Assigned (${this.counts.assignedCount})`;
          case this.$constants.FORM_INSTANCE_STATUS.SUBMITTED:
            return `Submitted (${this.counts.submittedCount})`;
          case this.$constants.FORM_INSTANCE_STATUS.REVIEWED:
            return `Reviewed (${this.counts.reviewedCount})`;
          case this.$constants.FORM_INSTANCE_STATUS.FINALIZED:
            return `Finalized (${this.counts.finalizedCount})`;
          default:
            return '';
        }
      };
    },
    isDefaultView() {
      return (
        this.$store.getters.persistentSettings[this.$route.name].customPageView
          .isDefault == 1
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.width <= 600;
    },
  },
  data() {
    return {
      tags: [],
      loaded: false,
      localFilter: {},
    };
  },
  methods: {
    getTags() {
      this.loaded = false;
      const params = {
        key: 'tags',
        filters:
          this.$store.getters.persistentSettings[this.$route.name].filters,
      };
      const url = 'get-items-for-form-filter?format=json';
      this.$axios.post(url, params).then((response) => {
        if (response.data.length > 0) {
          const none = { id: -1, val: 'None' };
          this.tags = response.data.filter((i) => i.id != '');
          this.tags.unshift(none);
        }
        this.loaded = true;
      });
    },
    clearFilters() {
      this.$emit('clearFilters');
    },
    onTagChanged(data) {
      //if its prevously selected and there is more than one new value
      if (this.localFilter.tagIds.some((i) => i == -1) && data.length > 1) {
        //remove the -1, none
        this.localFilter.tagIds = data.filter((i) => i != -1);
        return;
      }
      //if previously not non selected and then selected none
      if (
        this.localFilter.tagIds.some((i) => i != -1) &&
        data.some((i) => i == -1)
      ) {
        //remove all other values
        this.localFilter.tagIds = data.filter((i) => i == -1);
        return;
      }
    },
    init() {
      this.localFilter = JSON.parse(JSON.stringify(this.filters));
      if (!this.loaded) {
        this.getTags();
      }
    },
    confirm() {
      if (JSON.stringify(this.localFilter) != JSON.stringify(this.filters)) {
        this.$emit('confirm', JSON.parse(JSON.stringify(this.localFilter)));
        this.getTags();
      }
      this.$emit('close');
    },
  },
  mounted() {
    this.init();
  },
};
</script>
