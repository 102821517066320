var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","tile":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-subheader',_vm._g(_vm._b({staticClass:"font-weight-bold clickable black--text px-0",style:({ 'font-size': '0.75rem' }),attrs:{"label":""}},'v-subheader',attrs,false),on),[_vm._v(" "+_vm._s(_vm.header.text)+" "),(_vm.isHeaderActive)?_c('HeaderFilterIconAcitve'):_c('HeaderFilterIcon')],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{class:{
            'font-weight-bold': _vm.isBoldFont(0),
          },attrs:{"cols":"12"}},[_c('span',{staticClass:"clickable black--text",on:{"click":function($event){return _vm.updateSorting(0)}}},[_vm._v(" Sort from A-Z ")])]),_c('v-col',{staticClass:"clickable black--text",class:{
            'font-weight-bold': _vm.isBoldFont(1),
          }},[_c('span',{staticClass:"clickable black--text",on:{"click":function($event){return _vm.updateSorting(1)}}},[_vm._v(" Sort from Z-A ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","outlined":"","prepend-inner-icon":"mdi-magnify"},on:{"input":function($event){_vm.reload = !_vm.reload}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.searchedItems.length > 0 && _vm.itemsLoaded)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"mb-2"},[_c('v-checkbox',{staticClass:"mt-0 ml-1",attrs:{"dense":"","hide-details":"","label":_vm.selectAllText,"indeterminate":_vm.selected.length > 0 &&
                  _vm.selected.length < _vm.searchedItems.length,"color":"msaBlue"},on:{"change":_vm.toggleSelectAll},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1)],1),_c('v-virtual-scroll',{key:_vm.reload,attrs:{"items":_vm.searchedItems,"height":280,"item-height":40},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"shrink"},[_c('v-checkbox',{staticClass:"mt-0 pt-0 ml-1",attrs:{"value":item[_vm.itemValue],"dense":"","hide-details":""},on:{"change":_vm.checkSelectAll},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-col',{staticClass:"text-truncate",attrs:{"title":item[_vm.itemText]}},[_vm._v(" "+_vm._s(item[_vm.itemText])+" ")])],1)]}}],null,false,3255129431)}),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"text":"","small":"","color":"msaBlue","disabled":_vm.filterValue.length == 0 &&
                  !_vm.updateSort &&
                  !_vm.header.isSortingBy},on:{"click":function($event){return _vm.resetFilter()}}},[_vm._v(" r "),_c('span',{staticClass:"text-lowercase"},[_vm._v("eset filter")])])],1)],1)],1):(_vm.searchedItems.length == 0 && _vm.itemsLoaded)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"height":"280"}},[_c('div',{staticClass:"text-center"},[_vm._v("No results found")])])],1):_vm._e(),(!_vm.itemsLoaded)?_c('v-col',{attrs:{"align":"center"}},[_c('v-sheet',{attrs:{"height":"280"}},[_c('v-progress-circular',{attrs:{"size":50,"color":"msaBlue","indeterminate":""}})],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }