<template>
  <img src="../assets/icon-colum-filter.svg" />
</template>

<script>
export default {
  name: 'HeaderFilterIcon',
};
</script>

<style></style>
