<template>
  <v-container fluid>
    <v-dialog v-model="dialog" width="650" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          v-on="on"
          class="text-capitalize"
          height="50"
          v-blur
        >
          <v-row no-gutters>
            <v-col cols="12">
              <v-icon>mdi-view-week-outline</v-icon>
            </v-col>
            <v-col> Columns </v-col>
          </v-row>
        </v-btn>
      </template>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <!-- <div class="text-h6 mt-4">Add or remove columns</div> -->
              <v-footer
                padless
                tile
                style="position: sticky; top: 0; z-index: 999"
                color="white"
              >
                <v-container fluid>
                  <v-row align="center">
                    <v-col class="text-h6 mt-4"> Add or remove columns </v-col>
                  </v-row>
                </v-container>
              </v-footer>
              <template v-for="header in allHeaders">
                <v-checkbox
                  v-model="selected"
                  :key="header.id"
                  :value="header"
                  dense
                  hide-details
                  :label="header.text"
                  :disabled="
                    header.value == 'title' ||
                    header.value == 'createdOnMobileAt'
                  "
                ></v-checkbox>
              </template>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
              <v-footer
                padless
                tile
                style="position: sticky; top: 0; z-index: 999"
                color="white"
              >
                <div class="text-h6 mt-4">Your columns</div>
                <div>drag and drop to reorder how columns appear</div>
              </v-footer>
              <v-list dense>
                <Draggable @end="changed = true" :list="selected">
                  <v-list-item
                    v-for="(header, index) in selected"
                    :key="header.id"
                  >
                    <v-icon class="mr-4"> mdi-drag-horizontal-variant </v-icon>
                    <v-list-item-title>{{ header.text }}</v-list-item-title>
                    <v-btn
                      icon
                      small
                      v-if="
                        header.value != 'title' &&
                        header.value != 'createdOnMobileAt'
                      "
                      @click="removeHeader(index)"
                    >
                      <v-icon small>mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </v-list-item>
                </Draggable>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-footer
          padless
          tile
          style="position: sticky; bottom: 0; z-index: 999"
          color="white"
        >
          <v-container fluid>
            <v-row>
              <v-col class="grow" align="right">
                <v-btn color="msaBlue" text @click="cancel()"> cancel </v-btn>
              </v-col>
              <v-col class="shrink">
                <v-btn
                  class="msaBlue white--text"
                  @click="confirm()"
                  :disabled="!changed"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-footer>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Draggable from 'vuedraggable';
export default {
  name: 'HeaderSelector',
  components: {
    Draggable,
  },
  watch: {
    dialog(val) {
      if (val && !this.loaded) {
        this.getAllHeaders().then(() => this.init());
      }
    },
    'selected.length'() {
      this.changed = true;
    },
  },
  data() {
    return {
      dialog: false,
      selected: [],
      allHeaders: [],
      loaded: false,
      changed: false,
    };
  },
  methods: {
    getAllHeaders() {
      const params = {
        frontEndRouteName: this.$route.name,
      };

      const url = 'get-all-headers-for-view?format=json';
      return this.$axios.post(url, params).then((response) => {
        this.allHeaders = response.data;
        this.loaded = true;
      });
    },
    init() {
      const currentHeaders =
        this.$store.getters.persistentSettings[this.$route.name].headers;

      this.selected = this.allHeaders
        .filter((header) =>
          currentHeaders.some((currentHeader) => {
            const isSelected = currentHeader.pageViewHeaderId == header.id;
            if (isSelected) {
              header.position = currentHeader.position;
            }
            return isSelected;
          }),
        )
        .sort((a, b) => a.position - b.position);
    },
    cancel() {
      this.loaded = false;
      this.dialog = false;
    },
    confirm() {
      const store = this.$store.getters.persistentSettings[this.$route.name];
      const sortBy = store.headers.find((header) => header.isSortingBy);
      const headers = this.selected.map((header, index) => {
        return {
          position: index + 1,
          text: header.text,
          pageViewHeaderId: header.id,
          id: 0,
          isSortingDesc: 0,
          value: header.value,
          isSortingBy: 0,
          class: 'lightGrey',
        };
      });
      const newSortBy = headers.find(
        (header) => header.pageViewHeaderId == sortBy.pageViewHeaderId,
      );
      if (newSortBy) {
        newSortBy.isSortingBy = 1;
        newSortBy.isSortingDesc = sortBy.isSortingDesc;
      } else {
        const createdAt = headers.find(
          (header) => header.value == 'createdOnMobileAt',
        );
        createdAt.isSortingBy = 1;
        createdAt.isSortingDesc = 1;
      }
      const customPageView = {
        userId: this.$store.getters.user.id,
        isActive: 1,
        name: 'Custom',
        id: 0,
        isDefault: 0,
      };
      const newStore = {
        ...store,
        headers,
        customPageView,
      };
      this.$store.commit('updatePersistentSettings', {
        key: this.$route.name,
        value: newStore,
      });

      this.$emit('update');
      this.dialog = false;
    },
    removeHeader(index) {
      this.selected.splice(index, 1);
    },
  },
};
</script>

<style></style>
