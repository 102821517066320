<template>
  <img src="../assets/icon-colum-filter-active.svg" />
</template>

<script>
export default {
  name: 'HeaderFilterIconActive',
};
</script>

<style></style>
