<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        color="msaBlue"
        :disabled="disableSave"
        v-bind="attrs"
        v-blur
        v-on="on"
      >
        save view
      </v-btn>
    </template>

    <v-card>
      <v-card-title> Save view </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            Save your filters, column settings and date range to load them for
            next time.
          </v-col>
          <v-col>
            <v-text-field
              ref="name"
              v-model="name"
              dense
              outlined
              counter
              maxLength="200"
              label="Name"
              :rules="[(v) => $helpers.required(v, 'Name')]"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="msaBlue" text @click="cancel()"> cancel </v-btn>
        <v-btn
          class="msaBlue white--text"
          @click="save()"
          :disabled="!name || name.trim().length == 0"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SaveView',
  data() {
    return {
      dialog: false,
      name: '',
    };
  },
  methods: {
    cancel() {
      this.name = '';
      this.$refs.name.reset();
      this.dialog = false;
    },
    save() {
      const params = {
        frontEndRouteName: this.$route.name,
        name: this.name,
        headers: this.customPageViewContents.headers,
        filters: this.customPageViewContents.filters,
      };

      const url = 'save-custom-page-view?format=json';
      this.$axios.post(url, params).then((response) => {
        this.$store.commit('updatePersistentSettings', {
          key: this.$route.name,
          value: {
            ...this.customPageViewContents,
            customPageView: response.data,
          },
        });
        this.$emit('save');
        this.cancel();
      });
    },
  },
  computed: {
    disableSave() {
      return this.customPageViewContents.customPageView.id != 0;
    },
    customPageViewContents() {
      return this.$store.getters.persistentSettings[this.$route.name];
    },
  },
  watch: {},
  mounted() {},
};
</script>

<style></style>
